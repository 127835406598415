<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { userGridData } from "./data-user";
import { Api } from "../../../helpers";
import { Erreur } from "../../../helpers/error";
// import html2pdf from "html2pdf.js";

export default {
  page: {
    title: "Contact Users List",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      pageSize: 100, // Nombre d'éléments à afficher sur chaque page
      currentPage: 0, // La page actuelle
      role: "",
      usersList: [],
      activeFilter: 'all',
      roleList: [],
      filteredUsers: [],
      downloadCsv: false,
      searchQuery: "",
      showModal: false,
      spinner: false,
      userGridData: userGridData,
      title: "liste des utilisateurs",
      startDate: "",
      endDate: "",
      items: [
        {
          text: "Accès et autorisations",
          // href: "/contacts/list/"
        },
        {
          text: "Utilisateurs",
          active: true
        }
      ]
    };
  },
  methods: {
    goToPage(page) {
      // Vérifier si la page demandée est valide
      if (page >= 0 && page <= this.pageCount) {
        this.currentPage = page;
      }
    },

    filterArticles(filter) {
      if (filter === 'all') {
        this.filteredUsers = this.usersList;
      } else if (filter === 'activé') {
        this.filteredUsers = this.usersList.filter(user => user.enabled === true);
      } else if (filter === 'inactivé') {
        this.filteredUsers = this.usersList.filter(user => user.enabled === false);
      }
      this.filter = filter; // Mettre à jour l'état du filtre actif

      // Mettre à jour le filtre actif
      this.activeFilter = filter;
    },

    generateCsvFile() {
      // html2pdf(this.$refs.tableToPDF, {
      // 	margin: 1,
      //   filename: "i-was-html.pdf",
      // } );
      this.downloadCsv = true
      const csvContent = this.convertToCSV(this.filteredUsersList)
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf8' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Liste_des_utilisateurs.csv')
      link.click()
      this.downloadCsv = false
    },
    convertToCSV(data) {
      const header = Object.keys(data[0]);
      const rows = data.map(obj => header.map(header => obj[header]))
      const headerRow = header.join(',')
      const csvRows = [headerRow, ...rows.map(row => row.join(','))]

      return csvRows.join('\n')
    },

    formatNumberWithSpaces(number) {
            // Convertit le nombre en chaîne de caractères
            const numberString = number.toString();

            // Utilise une expression régulière pour ajouter un espace après chaque groupe de trois chiffres
            const formattedNumber = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

            return formattedNumber;
        }
  },
  computed: {
    filteredUsersList() {
      if (!this.startDate || !this.endDate) {
        if (!this.searchQuery) {
          return this.filteredUsers;
        }
        return this.filteredUsers.filter((user) => {
          const fullName = user.surname + " " + user.name + " " + user.username;
          return fullName.toLowerCase().includes(this.searchQuery.toLowerCase());
        });
      }

      const start = new Date(this.startDate).getTime();
      const end = new Date(this.endDate).getTime();


      return this.filteredUsers.slice().reverse().filter((user) => {
        const createdAt = new Date(user.created).getTime();
        const title = user.name.toLowerCase();
        const searchQuery = this.searchQuery.toLowerCase();

        return (
          createdAt >= start && createdAt <= end && title.includes(searchQuery)
        );
      });
    },
    pages() {
      // Générer une liste de nombres de pages à afficher dans la pagination
      let pages = [];
      for (let i = 0; i <= this.pageCount - 1; i++) {
        pages.push(i);
      }
      return pages;
    },
    pageCount() {
      return Math.ceil(this.filteredUsersList.length / this.pageSize);
    },
    paginatedVideos() {
      const start = this.currentPage * this.pageSize;
      const end = start + this.pageSize;
      return this.filteredUsersList.slice(start, end);
    }
  },
  mounted() {
    this.spinner = true
    Api.get("/authentication/api/auth/user/all")
      .then((res) => {
        this.spinner = false
        this.usersList = res.data.content
        console.log(this.usersList)
        this.filterArticles('all')
      }).catch((error) => {
        this.process = false
        this.spinner = false
        console.log(error)
        Erreur.gestionErreur(error.message)
      })
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div style="display: flex; align-items: center; justify-content: space-between;">
      <h4>{{ this.formatNumberWithSpaces(filteredUsersList.length) }} utilisateur<span v-if="filteredUsersList.length > 1">s</span></h4>
      <button class="btn c2play-primary" @click="generateCsvFile"><span style="color: #fff"><i class="mdi mdi-download font-size-18"></i></span></button>
    </div>
    
    <div class="row mb-3">
      <div class="col-lg-5">
        <br><br>
        <div class="input-group">
          <input type="text" class="form-control" placeholder="Rechercher un utilisateur" style="border-radius: 8px;"
            v-model="searchQuery">
        </div>
      </div>

      <div class="col-lg-7">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label for="projectname" class="col-form-label">Date de début</label>
              <div class="col-lg-12">
                <input id="projectname" name="projectname" type="date" class="form-control" v-model="startDate" />
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="projectname" class="col-form-label">Date de fin</label>
              <div class="col-lg-12">
                <input id="projectname" name="projectname" type="date" class="form-control" v-model="endDate"
                  :min="startDate" />
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="row">
      <div style="display: flex; justify-content: center; align-items: center;">
        <button @click="filterArticles('all')" class="btn"
          :class="{ 'c2play-primary': activeFilter === 'all' }">Tous</button>
        <button @click="filterArticles('activé')" class="btn"
          :class="{ 'c2play-primary': activeFilter === 'activé' }">Activés</button>
        <button @click="filterArticles('inactivé')" class="btn"
          :class="{ 'c2play-primary': activeFilter === 'inactivé' }">Non activés</button>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive" ref="tableToPDF">
              <table class="table align-middle table-nowrap table-hover">
                <thead class="table-light">
                  <tr>
                    <th scope="col" style="width: 70px;">#</th>
                    <th scope="col">{{ $t('pages.members.entete.th1') }}</th>
                    <th scope="col">{{ $t('pages.members.entete.th2') }}</th>
                    <th scope="col">{{ $t('pages.members.entete.th3') }}</th>
                    <th scope="col">{{ $t('pages.members.entete.th4') }}</th>
                    <th scope="col">{{ $t('pages.members.entete.th5') }}</th>
                    <th scope="col">Statut</th>
                    <th scope="col">{{ $t('pages.members.entete.th6') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="user in paginatedVideos" :key="user.id">
                    <td>
                      <div v-if="!user.image" class="avatar-xs">
                        <span class="avatar-title rounded-circle c2play-primary">{{ user.surname.charAt(0).toUpperCase()
                        }}{{ user.name.charAt(0).toUpperCase() }}</span>
                      </div>
                      <div v-if="user.image">
                        <img class="rounded-circle avatar-xs" :src="`${user.image.url}`" alt />
                      </div>
                    </td>
                    <td>
                      <h5 class="font-size-14 mb-1">
                        {{ user.surname }} {{ user.name }}
                      </h5>
                    </td>
                    <td>{{ user.username }}</td>
                    <td>
                      <span v-if="user.email">{{ user.email }}</span>
                    </td>
                    <td>
                      <span v-if="user.telephone">{{ user.telephone }}</span>
                    </td>
                    <td>
                      <p v-for="role in user.roles" :key="role.id">
                        <span v-if="role.name == 'ROLE_ADMIN'" class="bg-success badge">{{ $t('roles.admin') }}</span>
                        <span v-if="role.name == 'ROLE_MODERATOR'" class="bg-warning badge">{{ $t('roles.mod') }}</span>
                        <span v-if="role.name == 'ROLE_CREATOR'" class="bg-danger badge">{{ $t('roles.create') }}</span>
                        <span v-if="role.name == 'ROLE_AWARD'" class="bg-primary badge">Award</span>
                        <span v-if="role.name == 'ROLE_EDITOR'" class="bg-info badge">{{ $t('roles.edit') }}</span>
                      </p>
                    </td>
                    <td>
                      <span v-if="user.enabled == true" class="bg-success badge">Activé</span>
                      <span v-if="user.enabled == false" class="bg-danger badge">Non activé</span>
                    </td>
                    <td>
                      <b-dropdown class="card-drop" variant="white" menu-class="dropdown-menu-end" right
                        toggle-class="p-0">
                        <template v-slot:button-content>
                          <i class="mdi mdi-dots-horizontal font-size-18"></i>
                        </template>
                        <b-dropdown-item :href="'/contacts/updateProfile/' + user.id">{{
                          $t('pages.members.actions.details') }}</b-dropdown-item>
                      </b-dropdown>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div style="text-align: center; font-size: 3em;" v-if="spinner == true"><b-spinner variant="success" role="status"></b-spinner></div>

            </div>
          </div>
        </div>
      </div>
    </div>


    <div>
      <nav style=" width: 100%; display: flex;align-items: center;justify-content: center;" v-if="pages.length > 1">
        <ul class="pagination">
          <li class="page-item" :class="{ disabled: currentPage === 0 }">
            <button class="page-link" style=" margin: 5px; font-size: 15px; border-radius: 20px 0 0 20px;"
              @click="goToPage(currentPage - 1)">Previous </button>
          </li>
          <div style="max-width: 800px; justify-content: center;" class="display">
            <li v-for="page in pages" :key="page" class="page-item">
              <button class="page-link" :class="{ active2play: currentPage === page }"
                style="height: 45px;width: 45px; border-radius: 50%; margin: 5px;" @click="goToPage(page)">{{
                  page + 1
                }}</button>
            </li>
          </div>
          <li class="page-item" :class="{ disabled: currentPage === pageCount - 1 }">
            <button class="page-link" style=" margin: 5px; font-size: 15px; border-radius: 0 20px 20px 0;"
              @click="goToPage(currentPage + 1)">Next </button>
          </li>
        </ul>
      </nav>
    </div>
  </Layout>
</template>